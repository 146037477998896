import { ReactElement, useCallback, useState } from 'react'

import { LoadingOutlined, UploadOutlined } from '@ant-design/icons'
import {
  Image,
  Divider,
  Form,
  GetProp,
  Spin,
  Upload,
  UploadFile,
  UploadProps,
  Tooltip,
  Button,
} from 'antd'
import { toast } from 'sonner'

import * as S from './styles'

import UploadImage from '@/assets/upload-image.svg'
import { MAX_UPLOAD_FILE_SIZE, MAX_UPLOAD_IMAGE_COUNT } from '@/common'
import { useS3Url, validateFilesBeforeUpload } from '@/features/BG'
import type { BgFileUploadData, CreateProductFormValues, UploadStatus } from '@/features/BG/types'

type CreateBGFormProps = {
  setImagePackData: React.Dispatch<React.SetStateAction<BgFileUploadData[]>>
  formValues: CreateProductFormValues
  imagePackData: BgFileUploadData[]
  hasInitialValues?: boolean
  uploadStatus: UploadStatus
  setUploadStatus: React.Dispatch<React.SetStateAction<UploadStatus>>
  supportFilesData: BgFileUploadData[]
  setSupportFilesData: React.Dispatch<React.SetStateAction<BgFileUploadData[]>>
}

type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0]

export const UploadImages = ({
  setImagePackData,
  formValues,
  imagePackData,
  hasInitialValues,
  uploadStatus,
  setUploadStatus,
  supportFilesData,
  setSupportFilesData,
}: CreateBGFormProps) => {
  const fetchS3Url = useS3Url()
  const form = Form.useFormInstance()
  const [previewOpen, setPreviewOpen] = useState(false)
  const [previewImage, setPreviewImage] = useState('')
  const [selectedImage, setSelectedImage] = useState<string | null>(
    () => form.getFieldValue('image_cover_bg') || null,
  )

  const selectedProductType = formValues?.product_type?.label

  const getBase64 = (file: FileType): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result as string)
      reader.onerror = (error) => reject(error)
    })

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as FileType)
    }

    setPreviewImage(file.url || (file.preview as string))
    setPreviewOpen(true)
  }

  const handleUploadFile = useCallback(
    async (file: File, uploadType: 'image' | 'support') => {
      if (uploadStatus === 'uploading') return

      setUploadStatus('uploading')

      try {
        const result = await fetchS3Url(file)

        if (uploadType === 'image') {
          setImagePackData((oldData) => [...oldData, result])
        }

        if (uploadType === 'support') {
          setSupportFilesData((oldData) => [...oldData, result])
        }

        toast.success(
          <S.ToastContainer>
            <S.CircleOutlined />

            <div>
              {file.name} <span style={{ fontWeight: 'bold' }}>carregado</span> com{' '}
              <span style={{ fontWeight: 'bold' }}>sucesso</span>
            </div>
          </S.ToastContainer>,
          {
            position: 'bottom-right',
            className: 'toast-sucess',
          },
        )
      } catch (error) {
        console.error('Error uploading file:', error)
        toast.error(`Erro ao carregar arquivo ${file.name}.`)
      } finally {
        setUploadStatus('done')
      }
    },
    [uploadStatus, fetchS3Url, setImagePackData],
  )

  const imageUploaderProps: UploadProps = {
    multiple: true,
    action: '',
    showUploadList: true,
    maxCount: MAX_UPLOAD_IMAGE_COUNT,
    listType: 'picture-card',
    accept: '.jpg, .png, .jpeg',
    className: 'uploadDragger',
    defaultFileList: imagePackData.map((image) => {
      let url: string | undefined

      if (!hasInitialValues && image.file) {
        url = URL.createObjectURL(image.file as File)
      }

      return {
        ...image,
        name: (image?.file?.name as string) || image.filename,
        uid: image.filename,
        thumbUrl: url || image.url,
      }
    }),
    onPreview: handlePreview,
    beforeUpload: (file: File, fileList: UploadFile[]) => {
      // const validationResult = validateBeforeUpload(file, fileList, imagePackData, MAX_UPLOAD_IMAGE_COUNT)
      const validationResult = validateFilesBeforeUpload(
        file,
        fileList,
        imagePackData,
        MAX_UPLOAD_IMAGE_COUNT,
        MAX_UPLOAD_FILE_SIZE,
      )

      if (validationResult) {
        handleUploadFile(file, 'image')
        return false
      }

      return Upload.LIST_IGNORE
    },
    onChange: ({ fileList }) => {
      const hasMaximunFiles = fileList.length >= MAX_UPLOAD_IMAGE_COUNT

      if (hasMaximunFiles) {
        toast.info(`Limite de ${MAX_UPLOAD_IMAGE_COUNT} imagens atingido.`)
      }
    },
    onRemove: (file) => {
      let newImagePackData: BgFileUploadData[] = []

      if (hasInitialValues) {
        newImagePackData = imagePackData.filter((image) => image.filename !== file.uid)

        const excludedFiles = form.getFieldValue('excludedFiles') || []

        form.setFieldValue('excludedFiles', [...excludedFiles, file.uid])
      } else {
        if (file.thumbUrl) {
          URL.revokeObjectURL(file.thumbUrl as string)
        }

        newImagePackData = imagePackData.filter((image) => {
          return image?.file?.name !== file.name
        })
      }

      if (file.uid === selectedImage) {
        setSelectedImage(null)
        form.setFieldValue('image_cover_bg', null)
      }

      setImagePackData(newImagePackData)
    },
  }

  const ImageRender = (originNode: ReactElement, file: UploadFile) => {
    return (
      <S.UploadedImageContainer>
        {originNode}

        <Tooltip title={file.name}>
          <S.ImageName>{file.name}</S.ImageName>
        </Tooltip>

        <Form.Item
          name="image_cover_bg"
          getValueProps={() => ({
            checked:
              (selectedImage && selectedImage === file.uid) ||
              selectedImage === (file as any)?.file?.uid ||
              false,
          })}
        >
          <S.ImageCheckbox
            value={file.uid}
            onChange={(e) => {
              setSelectedImage(e.target.value)
            }}
            checked={
              (selectedImage && selectedImage === file.uid) ||
              selectedImage === (file as any)?.file?.uid ||
              false
            }
          />
        </Form.Item>
      </S.UploadedImageContainer>
    )
  }

  const supportFilesUploaderProps: UploadProps = {
    multiple: true,
    action: '',
    showUploadList: true,
    onPreview: undefined,
    accept:
      '.docx, .doc, .pdf, .ppt, .pptx, .pps, .ppsx, .pot, .potx, .key, .odp, .otp, .sxi, .sti, .png, .jpg, .jpeg, .svg, .webp, .zip, .rar, .xls, .xlsx, .csv, .psd, .tif, .tiff',
    defaultFileList: supportFilesData.map((file) => {
      return {
        ...file,
        name: (file?.file?.name as string) || file.filename,
        uid: file.filename,
      }
    }),
    beforeUpload: (file: File, fileList: UploadFile[]) => {
      const validationResult = validateFilesBeforeUpload(file, fileList, supportFilesData)

      if (validationResult) {
        handleUploadFile(file, 'support')
        return false
      }

      return Upload.LIST_IGNORE
    },
    onRemove: (file) => {
      let newSupportFilesData: BgFileUploadData[] = []

      if (hasInitialValues) {
        newSupportFilesData = supportFilesData.filter((f) => f.filename !== file.uid)

        const excludedSupportFiles = form.getFieldValue('excludedSupportFiles') || []

        form.setFieldValue('excludedSupportFiles', [...excludedSupportFiles, file.uid])
      } else {
        console.log(file.name, supportFilesData)
        newSupportFilesData = supportFilesData.filter((f) => {
          return f?.file?.name !== file.name
        })
      }

      setSupportFilesData(newSupportFilesData)
    },
  }

  return (
    <S.ThirdStepFormContainer
      $uploaderEmpty={imagePackData.length === 0 || uploadStatus === 'uploading'}
    >
      {previewImage && (
        <Image
          src={previewImage}
          wrapperStyle={{ display: 'none' }}
          preview={{
            visible: previewOpen,
            onVisibleChange: (visible) => setPreviewOpen(visible),
            afterOpenChange: (visible) => !visible && setPreviewImage(''),
          }}
        />
      )}

      <S.TitleContainer>
        <S.ProductCategory>
          <span>{selectedProductType || '--'}</span>

          <Divider type="vertical" style={{ margin: 0 }} />

          <strong>{formValues?.category?.label || '--'}</strong>

          <Divider type="vertical" style={{ margin: 0 }} />

          <span>{formValues.ref_id || '--'}</span>
        </S.ProductCategory>

        <S.BGName>{formValues.model}</S.BGName>

        <S.UploaderIntruction>
          Adicione um total de 10 imagens para o produto.{' '}
          <strong>({imagePackData.length}/10)</strong>
        </S.UploaderIntruction>

        <S.UploaderIntruction>
          Após inserir as imagens do produto, selecione uma delas para ser a capa das
          especificações.
        </S.UploaderIntruction>
      </S.TitleContainer>

      <Form.Item
        className="uploadDraggerBox"
        name="bgFile"
        rules={[
          { required: true, message: 'Insira pelo menos uma imagem para o produto.' },
          {
            pattern: new RegExp(`[/.(jpg|png|jpeg)$/i]`),
            message: 'Formato de arquivo inválido. São aceitas apenas imagens JPG, JPEG ou PNG',
          },
        ]}
      >
        <S.ListedImageContainer>
          <Upload
            itemRender={ImageRender}
            disabled={uploadStatus === 'uploading'}
            {...imageUploaderProps}
          >
            {(!uploadStatus || uploadStatus === 'done') && (
              <S.UploadStatusContainer $isDone={imagePackData.length >= 10}>
                <img src={UploadImage} alt="upload bg" />
              </S.UploadStatusContainer>
            )}

            {uploadStatus === 'uploading' && (
              <S.LoadingStatusContainer>
                <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
              </S.LoadingStatusContainer>
            )}
          </Upload>
        </S.ListedImageContainer>
      </Form.Item>

      <S.UploadSupportFilesContainer>
        <Upload {...supportFilesUploaderProps}>
          <S.UploaderIntruction>Anexar outros arquivos</S.UploaderIntruction>

          <Button icon={<UploadOutlined />} />
        </Upload>
      </S.UploadSupportFilesContainer>
    </S.ThirdStepFormContainer>
  )
}
